import React from "react";
import { PageSections } from "@happy-rabbit/gatsby-sanity-cms";

import { resolvePageSection } from "../PageSections";

import SchemaOrg from "./StorySEO";

const StoryView = props => {
  const { story } = props;

  return (
    <>
      <SchemaOrg story={story} />

      <PageSections
        pageSections={story.pageSections}
        resolvePageSection={resolvePageSection}
      />
    </>
  );
};

export default StoryView;
