import React from "react";
import { Helmet } from "react-helmet";
import { combineLocale, getLocaleValue, useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import SEO from "../SEO";
import { structuredImageArray } from "../SEO/JsonLd";

const StorySEO = props => {
  const { story } = props;
  const { lang, pageConfig, path, siteMetadata } = useCMSContext();

  const title = getLocaleValue(combineLocale([story.previewTitle, pageConfig.pageSeo?.title]), lang);
  const description = getLocaleValue(combineLocale([story.previewSummary, pageConfig.pageSeo?.description]), lang);
  const image = pageConfig.pageSeo?.image;

  return (
    <>
      <SEO
        pageFields={{
          title,
          description,
          image,
        }}
      />

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": title,
            ...(description ? { "description": description } : null),
            "image": structuredImageArray(image),
            "datePublished": story.publishedAt,
            "dateModified": story.publishedAt,
            "mainEntityOfPage": {
              '@type': 'WebSite',
              '@id': `${siteMetadata.siteUrl}${path}`,
            },
          })}
        </script>
      </Helmet>
    </>
  );
}

export default StorySEO;
