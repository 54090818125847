import React from "react";
import { urlFor } from "../../utils/sanity";

export const structuredImageArray = image => {
  if (!image)
    return [];

  return [
    urlFor(image).width(1280).height(parseInt(1280 / (16/9))).url(),
    urlFor(image).width(1280).height(parseInt(1280 / (4/3))).url(),
    urlFor(image).width(1280).height(1280).url(),
  ];
};

const JsonLd = ({ children }) => {
  return (
    <script type="application/ld+json">
      {JSON.stringify(children)}
    </script>
  );
};

export default JsonLd;
