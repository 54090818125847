import React from "react";
import { graphql } from "gatsby";

import Layout from "../parts/Layout";
import { useMetadata } from "../queries/metadata";
import StoryView from "../parts/StoryView";
import { CMSProvider, ThemesProvider } from "@happy-rabbit/gatsby-sanity-cms";


const StoryTemplate = props => {
  const {
    data,
    pageContext,
    path,
  } = props;

  const site = data.allSanitySite.edges[0].node;
  const story = data.allSanityStory.edges[0].node;
  const { pageConfig, pageDesign } = story;
  const siteMetadata = useMetadata();

  const { lang } = pageContext;

  const {
    themeReferences: themes,
  } = site || {};

  return (
    <CMSProvider context={{ ...pageContext, pageConfig, page: story, path, site, siteMetadata }}>
      <ThemesProvider themes={themes}>
        <Layout lang={lang} pageDesign={pageDesign}>
          <StoryView story={story} />
        </Layout>
      </ThemesProvider>
    </CMSProvider>
  )
}

export const pageQuery = graphql`
query($siteId: String!, $storyId: String!) {
  allSanitySite (filter: {_id: {eq: $siteId}}) {
    edges {
      node {
        _id

        defaultCurrencyCode
        slug {
          current
        }
        themeReferences {
          ...SanityThemeFragment
        }
      }
    }
  }

  allSanityStory (filter: {_id: {eq: $storyId}}) {
    edges {
      node {
        _id
        _type

        pageConfig {
          ...SanityPageConfigFragment
        }
        pageDesign {
          ...SanityPageDesignFragment
        }
        pageSections {
          ... on SanityPageSectionsForm {
            ...SanityPageSectionsFormFragment
          }
          ... on SanityPageSectionsGrid {
            ...SanityPageSectionsGridFragment
          }
          ... on SanityPageSectionsHero {
            ...SanityPageSectionsHeroFragment
          }
          ... on SanityPageSectionsImage {
            ...SanityPageSectionsImageFragment
          }
          ... on SanityPageSectionsImgAndText {
            ...SanityPageSectionsImgAndTextFragment
          }
          ... on SanityPageSectionsLeadParagraph {
            ...SanityPageSectionsLeadParagraphFragment
          }
          ... on SanityPageSectionsNewsletter {
            ...SanityPageSectionsNewsletterFragment
          }
          ... on SanityPageSectionsParagraph {
            ...SanityPageSectionsParagraphFragment
          }
          ... on SanityPageSectionsProductIndex {
            ...SanityPageSectionsProductIndexFragment
          }
          ... on SanityPageSectionsPromotedStories {
            ...SanityPageSectionsPromotedStoriesFragment
          }
          ... on SanityPageSectionsQuickNav {
            ...SanityPageSectionsQuickNavFragment
          }
          ... on SanityPageSectionsSizeGuide {
            ...SanityPageSectionsSizeGuideFragment
          }
          ... on SanityPageSectionsStoryIndex {
            ...SanityPageSectionsStoryIndexFragment
          }
          ... on SanityPageSectionsTemplate {
            ...SanityPageSectionsTemplateFragment
          }
          ... on SanityPageSectionsTwoImages {
            ...SanityPageSectionsTwoImagesFragment
          }
          ... on SanityPageSectionsVimeo {
            ...SanityPageSectionsVimeoFragment
          }
          ... on SanityPageSectionsYoutube {
            ...SanityPageSectionsYoutubeFragment
          }
        }
      }
    }
  }
}
`;

export default StoryTemplate;
